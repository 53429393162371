<template>
    <div class="medium">
        <div>
            <!-- <div class="text">媒体文件转文字</div> -->
            <el-upload class="upload-demo" drag :action="$api.file_upload + '?token=' + token" :limit="2" ref="upload" list-type="picture" show-file-list :file-list="fileListUpload" :on-remove="onRemoveupload" :on-success="onSuccessupload" :on-error='onError' :before-upload="onChangeUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip text" slot="tip">最多可传 1 个 <el-button type="primary" @click="onSubmit()" :icon="disabled == 0 ? '' : 'el-icon-loading'" style="margin-left:20px" :disabled='disabled == 0 ? false : true'>{{disabled == 0 ? '提交识别' : disabled == 1 ? '正在上传' : '正在识别'}}</el-button>
                </div>
            </el-upload>
            <!-- <video ref="VideoDetailUrl" controls v-if="videoData.id">
                        <source :src="videoData.url_link + '?token=' + Token" type="video/mp4">
                    </video> -->
        </div>

        <!-- <el-button type="primary" @click="onGain()">获取结果</el-button> -->

        <mediumList ref="mediumList"></mediumList>
    </div>
</template>

<script>
import mediumList from "./mediumList.vue";
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).token,
            fileListUpload: [],
            file_id: "",
            disabled:0,
            
        };
    },
    components: {
        mediumList,
    },
    mounted() {
        // this.onceshi();
    },

    methods: {
        // 测试用
        // onceshi() {
        //     this.$service.get(this.$api.mediaList, "", (res) => {
        //         if (res.code == "200") {
        //             console.log(res);
        //         }
        //     });
        // },
        // 提交识别
        onSubmit() {
            var param = {
                file_id: this.file_id,
            };
            if (param.file_id == "") {
                this.$message({ message: "请上传文件", type: "warning" });
                return;
            }
            this.disabled = 2 
            this.$service.post(this.$api.mediaUpload, param, (res) => {
            this.disabled = 0
                if (res.code == "200") {
                    this.$message({
                        message: "文件识别成功",
                        type: "success",
                    });
                    this.fileListUpload = [];
                    this.$refs.mediumList.ongetVideoOrderList(1);
                }
            });
        },
        onGain() {
            var param = {
                id: 5,
            };
            this.$service.get(this.$api.getAudioResult, param, (res) => {
                if (res.code == "200") {
                    // console.log(res);
                    // if (res.data) {
                    //     this.ongetVideoOrderList(1);
                    // } else {
                    //     setTimeout(() => {
                    //         this.onGain(id);
                    //     }, 2000);
                    // }
                    // console.log("获取结果", res);
                }
            });
        },
        //上传文件移出
        onRemoveupload(file, fileList) {
            console.log("上传文件移出", fileList);
            this.fileListUpload = fileList;
            this.file_id = "";
            this.$message({
                message: "文件移出成功，请重新上传文件",
                type: "success",
            });
        },
        onKong(file, fileList) {
            console.log("上传文件移出", fileList);
            this.fileListUpload = fileList;
            this.file_id = "";
        },
        //文件上传成功
        onSuccessupload(res, file, fileList) {
            this.disabled = 0
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            if (res.code == 200) {
                this.file_id = res.data.id;
                this.onSubmit()
            }
        },
        //文件上传之前
        onChangeUpload(file, fileList) {
            this.disabled = 1 
            // console.log(file, "文件上传成功，失败 所有都会调用");
        },
        //文件上传失败
        onError(){
            this.disabled = 0
        }
    },
};
</script>

<style lang="scss" scoped>
.medium {
    .text {
        margin-left: 20px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 16px;
    }
}
</style>
<style lang="scss">
.medium {
}
</style>