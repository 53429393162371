<template>
    <div class="tofilmList">
        <div class="tofilm-top">
            <div class="tofilm-title">
                <div class="title">媒体文件转文字列表</div>
            </div>
            <div class="tofilm-prompt">
            </div>
        </div>
        <div class="listtable">
            <el-table border :data="tableData" v-loading="remandLoading" style="width: 100%" empty-text="暂无数据">
                <el-table-column prop="file.file_name" label="文件名"></el-table-column>
                <el-table-column prop="type" label="识别状态">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 1" style="color:#409EFF">识别中</div>
                        <div v-else-if="scope.row.type == 2" style="color:#67C23A">识别完成</div>
                        <div v-else style="color:red">识别失败</div>
                    </template>
                </el-table-column>
                <el-table-column prop="text" label="识别结果">
                    <template slot-scope="scope">
                        <el-popover placement="bottom" @show="onPopoverClick(scope.row)" width="500" trigger="click" :content="scope.row.text">
                            <span class="copyInfo" slot="reference" v-if="scope.row.text!=null">{{scope.row.text}}</span>
                            <span slot="reference" v-if="scope.row.text==''||scope.row.text==null">--</span>
                        </el-popover>
                    </template>

                </el-table-column>
                <el-table-column prop="created_at" label="上传时间"></el-table-column>
                <el-table-column prop="date" label="操作" width="150">
                    <template slot-scope="scope">
                        <div class="center">
                            <el-button type="primary" @click="onOrderxq(scope.row.file.file_url)">点击查看</el-button>
                        </div>
                        <div class="center" style="margin-top:10px" v-if="scope.row.file.file_url">
                        <el-button type="success" @click="doCopy(scope.row)">复制文本</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
        </div>
        <el-dialog custom-class="orderdetali" title="查看详情" :visible.sync="orderdetalis" width="600px" @close='closeDialog'>
            <div class="detalis">
                <video :src="url_link+ '?token=' + token" type="video/mp4" controls width="100%" height="500PX">

                </video>
            </div>
        </el-dialog>
        <!-- 支付 -->
        <!-- <Payorder ref="Payorder"></Payorder> -->
    </div>
</template>

<script>
// import Payorder from "../tofilm/payorder.vue";
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).token,
            tableData: [], //订单列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            remandLoading: false, //loading加载
            orderdetalis: false, //订单详情弹窗
            url_link: "",
            arr: [],
            mediaKey: 0,
            Timeout: null,
        };
    },
    components: {
        // Payorder,
    },
    mounted() {
        this.ongetVideoOrderList(1);
    },

    methods: {
         //删除文案
        doCopy(msg){
            var that = this
            this.$copyText(msg.text).then(
                function (e) {
                    that.$message({message: '复制成功',type: 'success'});
                    // that.onclickCount(msg.id,2)
                },
                function (e) {
                    that.$message({message: '复制失败，请重试',type: 'error'});
                }
            );
        },
        // 配音订单列表
        ongetVideoOrderList(val) {
            var param = {
                page: val,
                pre_page: this.per_page,
            };
            // this.remandLoading = true;
            this.$service.get(this.$api.mediaList, param, (res) => {
                if (res.code == "200") {
                    var data = res.data.data;
                    this.sds(data);
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.count;
                }
            });
        },
        // 执行获取结果
        sds(data) {
            if (data.length) {
                if (data.length == this.mediaKey) {
                    return;
                } else {
                    if (data[this.mediaKey].type == 1) {
                        return this.onGain(data[this.mediaKey].id);
                    } else {
                        this.mediaKey++;
                        this.sds(data);
                    }
                }
            }
        },
        onGain(id) {
            console.log(id);
            var param = {
                id: id,
            };
            this.$service.get(this.$api.getAudioResult, param, (res) => {
                if (res.code == "200") {
                    if (res.data.type == 2 || res.data.type == 3) {
                        // clearInterval(this.Timeout);
                        this.ongetVideoOrderList(1);
                    } else {
                        this.Timeout = setTimeout(() => {
                            this.onGain(id);
                        }, 3000);
                    }
                    // console.log("获取结果", res);
                }
            });
        },
        // 关闭弹窗
        closeDialog() {
            this.url_link = "";
            // this.genjin = {};
            this.orderdetalis = false;
        },
        //翻页
        DialogCurrentChange(val) {
            this.ongetVideoOrderList(val);
        },
        //订单详情
        onOrderxq(row) {
            this.url_link = row;
            this.orderdetalis = true;

            // window.open(row);
        },
    },
};
</script>

<style lang="scss" scoped>
.tofilmList {
    .tofilm-top {
        border-bottom: 1px solid #ededed;
        padding-bottom: 5px;
        margin-top: 30px;
        .tofilm-title {
            display: flex;
            font-weight: 500;
            padding-bottom: 15px;
            .title {
                font-size: 22px;
            }
        }
        .tofilm-prompt {
            display: flex;
            justify-content: space-between;
            .horn {
                padding: 5px 10px;
                background: #fefae9;
                color: #ff9a56;
            }
        }
    }
    .listtable {
        margin-top: 20px;
    }
}
@media screen and (max-width: 750px) {
    .tofilmList {
        .tofilm-top {
            .tofilm-prompt {
                display: block;
                .text {
                    margin-bottom: 10px;
                }
            }
        }
        .gongdan {
            .dan {
                &.chang .text {
                    width: 3rem;
                }
                &.chang .inputbox {
                    width: 3rem;
                }
                .text {
                    width: 1.8rem;
                }
                .inputbox {
                    width: 5.7rem;
                }
            }
            .enderQueren {
                padding-bottom: 0.5rem;
                padding-top: 0;
                margin: 0 auto;
            }
        }
    }
}
</style>
<style  lang="scss">
.tofilmList {
    .listtable {
        .cell {
            text-align: center;
        }
    }
    .copyInfo {
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        &:hover {
            text-decoration: underline;
        }
    }
    .el-dialog__title {
        color: #fff;
    }
    .el-dialog__header {
        background: #409eff;
    }
    .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
    }
}
</style>
